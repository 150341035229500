// src/components/Header.tsx
import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import {IconButton, Menu, MenuItem} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu'; // Import MenuIcon

const Header: React.FC = () => {
    // Define custom type for display property
    type DisplayType = 'initial' | 'inherit' | 'none' | 'inline' | 'block' | 'inline-block' | 'flex' | 'grid' | 'inline-grid' | 'contents';

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const isMobile = window.innerWidth < 600; // Adjust the breakpoint as needed

    return (
        <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Link to={"/"} style={{ color: 'inherit', textDecoration: 'none' }}>
                    <Typography variant="h6" sx={{ flexGrow: 1, fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                        LYNNO
                    </Typography>
                </Link>
                {isMobile ? (
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMenuOpen}
                    >
                    <MenuIcon />
                    </IconButton>
                ) : (
                    <div style={{ display: 'flex' }}> {/* Show menu items */}
                        <Link to="/technology" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <MenuItem>TECHNOLOGY</MenuItem> {/* Link to about */}
                        </Link>
                        <Link to="/industry" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <MenuItem>INDUSTRY</MenuItem> {/* Link to services */}
                        </Link>
                        <Link to="/contact" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <MenuItem>CONTACT</MenuItem> {/* Link to contact */}
                        </Link>
                    </div>
                )}
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: '#000', // Change background color here
                            border: '1px solid #ccc', // Add border here
                        },
                    }}
                >
                    <MenuItem onClick={handleMenuClose}>
                        <Link to="/technology" style={{ color: '#fff', textDecoration: 'none', marginLeft: '10px' }}>TECHNOLOGY</Link>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <Link to="/industry" style={{ color: '#fff', textDecoration: 'none', marginLeft: '10px' }}>INDUSTRY</Link>
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <Link to="/contact" style={{ color: '#fff', textDecoration: 'none', marginLeft: '10px' }}>CONTACT</Link>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
