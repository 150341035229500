// src/components/ContactUs.tsx
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {Card, CardContent, CardHeader, CardMedia} from "@mui/material";
import ImgAndroidDevelopment from "../assets/android_development.png";
import ImgIosDevelopment from "../assets/ios_development.png";

const Technology: React.FC = () => {
    return (
        <Container sx={{ marginTop : '60px'}}>
            <Typography variant="h3" component="h3" align="center" sx={{ color: '#fff' }}>
                Technologies We Work With
            </Typography>
            <Typography variant="h4" align="center" sx={{ color: '#fff', marginTop: '20px' }}>
                Custom Mobile Application Development
                <br/>
                Empowering Digital Transformation
            </Typography>
            <Typography variant="body1" align="center" sx={{ color: '#fff', fontSize: '1.5rem', marginTop: '20px' }}>
                We specialize in developing custom mobile applications that are tailored to meet your business requirements. Our team of experts is adept at leveraging the latest technologies to deliver innovative and scalable solutions that drive digital transformation and business growth.
            </Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '60px' }}>
                <AndroidAppDevelopment/>
                <div style={{ width: '20px' }}/>
                <IOSAppDevelopment/>
                </div>
            </div>
        </Container>
    );
};


function AndroidAppDevelopment() {
    return (
        <Card sx={{ maxWidth: 500, backgroundColor: '#333', color: '#fff' }}>
            <CardMedia
                component="img"
                height="200"
                image={ImgAndroidDevelopment}
                alt="Android Application Development"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: '1.5rem' }}>
                    Android Application Development
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '1rem' }}>
                    Our Android app development services are meticulously tailored to propel businesses towards their objectives with innovative and scalable solutions. Our unwavering dedication ensures the delivery of premium mobile applications finely tuned to meet your distinct business requirements
                </Typography>
            </CardContent>
        </Card>
    );
}

function IOSAppDevelopment() {
    return (
        <Card sx={{ maxWidth: 500, backgroundColor: '#333', color: '#fff' }}>
            <CardMedia
                component="img"
                height="200"
                image={ImgIosDevelopment}
                alt="Android Application Development"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: '1.5rem' }}>
                    iOS Application Development
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '1rem' }}>
                    Our iOS app development services are meticulously crafted to empower businesses in achieving their objectives through cutting-edge and scalable solutions. We are steadfast in our commitment to deliver premium-quality mobile applications precisely customized to address your unique business requirements.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default Technology;
