// src/components/Footer.tsx
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Footer: React.FC = () => {
    return (
        <footer style={{ backgroundColor: '#000', color: '#fff', padding: '20px 0', textAlign: 'center', marginTop: '40px', marginBottom: '40px' }}>
            <Container>
                <div style={{ marginBottom: '20px' }}>
                    <Typography variant="body1">
                        Lynno Technologies Private Limited
                    </Typography>
                    <Typography variant="body2">
                        CIN : U62011TN2024PTC166455
                    </Typography>
                    <Typography variant="body2">
                        10/50B Kunnamparai, Kattathurai, Kanyakumari District, Tamilnadu - 629158
                    </Typography>
                </div>
                <Typography variant="body2" style={{ marginBottom: '20px' }}>
                    © 2024 Lynno Technologies Private Limited. All rights reserved.
                </Typography>
            </Container>
        </footer>
    );
};

export default Footer;
