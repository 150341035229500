// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Technology from "./components/Technology";
import Industry from "./components/Industry";

const App: React.FC = () => {
    return (
        <Router>
            <div style={{ backgroundColor: 'black', width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <div style={{ margin: '0 auto' }}>
                    <Header /> {/* Add Header component */}
                    <Routes> {/* Add Routes component */}
                        <Route path="/" element={<Home />} />
                        <Route path="/technology" element={<Technology />} />
                        <Route path="/industry" element={<Industry />} />
                        <Route path="/contact" element={<ContactUs />} />
                    </Routes>
                </div>
                <Footer /> {/* Add Footer component */}
            </div>
        </Router>
    );
};

export default App;
