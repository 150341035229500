import React from "react";
import Container from "@mui/material/Container";
import {Card, CardContent, Typography} from "@mui/material";

interface IndustriesDto {
    title: String,
    description:String
}

// Add list of Industries
const industry: IndustriesDto[] = [
    {title : "eCommerce" , description: "We pioneer transformative technological solutions for the eCommerce sector, enriching digital interactions and enhancing brand experiences for consumers"},
    {title : "Finance" , description: "We drive the momentum of digital transformation in the financial services sector by developing cutting-edge payment gateways, wallets, and other innovative applications."},
    {title : "Restaurant" , description: "We specialize in crafting comprehensive restaurant management apps, empowering businesses to efficiently oversee products, orders, inventory, and procurement processes."},
    {title : "Real Estate" , description: "We specialize in developing robust software tailored for construction, enabling seamless planning, tracking, and accurate demand prediction capabilities."} ,
    {title : "Events" , description: "We engineer software solutions for the event management industry, empowering users to seamlessly manage their events in real-time, anytime, and anywhere."},
    {title : "Healthcare" , description : "We recognize the immediate advantages of digitizing medical care and support healthcare organizations in realizing these benefits while ensuring essential compliance standards are met."}
]

const Industry: React.FC = () => {
    return (
        <Container sx={{ marginTop : '60px'}}>
            <Typography variant="h3" component="h3" align="center" sx={{ color: '#fff' }}>
                Industries We Serve
            </Typography>
            <Typography variant="body1" align="center" sx={{ color: '#fff', fontSize: '1.5rem', marginTop: '20px' }} fontSize={20}>
                Delivering value to multiple industries, we innovate industry-specific technologies to scale and
                optimize operations and bottom line.
            </Typography>
            <div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {industry.map((item) => (
                        <Card sx={{ maxWidth: 300, marginLeft: 5, marginTop: 5,  backgroundColor: '#333', color: '#fff' }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: '1.5rem' }}>
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: '1rem' }}>
                                    {item.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default Industry;