// src/components/ContactUs.tsx
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const ContactUs: React.FC = () => {
    return (
        <Container sx={{ marginTop : '40px'}}>
            <Typography variant="h3" component="h3" align="center" sx={{ color: '#fff' }}>
                Contact Us
            </Typography>
            <Typography variant="body1" align="center" sx={{ color: '#fff', fontSize: '1.5rem', marginTop: '20px' }}>
                For any inquiries or feedback, please email us at: <a href="mailto:mail@lynnotech.com" style={{ color: '#fff', textDecoration: 'underline' }}>mail@lynnotech.com</a>
            </Typography>
        </Container>
    );
};

export default ContactUs;
