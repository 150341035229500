// src/components/Home.tsx
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ImgHighlight from "../assets/bg_highlight.png";
import Technology from "./Technology";
import Industry from "./Industry";
import ContactUs from "./ContactUs";

const Home: React.FC = () => {
    return (
        <Container>
            <HomeContent />
            <Technology />
            <Industry />
            <ContactUs />
        </Container>
    );
};

const HomeContent: React.FC = () => {
    return (
        <Container sx={{ marginTop : '60px'}}>
            <Typography variant="h2" component="h1" align="center" sx={{ color: '#fff' }}>
                Mobile App Development
            </Typography>
            <Typography fontSize={20} marginBottom={5}>
                Embrace the future of app development with our scalable solutions built on disruptive technologies
            </Typography>
            <div style={{
                position: 'relative',
                height: '40vh', // Adjust the height here
                backgroundImage: `url(${ImgHighlight})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}/>
        </Container>
    );
};



export default Home;
